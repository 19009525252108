import React, { useState } from "react"
import Logo from "../images/res-logo-white.svg"
import { Link } from "gatsby"
import SignupModal from "./signupModal"
import SubmitModal from "./submitModal"
import { AuthProvider, AuthConsumer } from "./utils/AuthContext"

const Navbar = () => {
  const [burger, setBurger] = useState(false)

  const handleLogin = () => {
    document.querySelector(".signup-modal").classList.toggle("is-active")
  }

  const handleSubmit = () => {
    document.querySelector(".submit-modal").classList.toggle("is-active")
  }

  return (
    <AuthProvider>
      <AuthConsumer>
        {({ isAuth, logout, user }) => (
          <nav
            className="navbar is-dark"
            role="navigation"
            aria-label="main navigation"
          >
            <div className="container">
              <div className="navbar-brand">
                <Link className="navbar-item" to="/">
                  <img src={Logo} width="112" height="28" alt="GrowKarma" />
                </Link>
                <a
                  className="navbar-item has-opacity-65"
                  href="https://growkarma.io"
                  style={{ backgroundColor: "transparent" }}
                >
                  <span className="mr-4">|</span> return to growkarma.io
                </a>

                <a
                  role="button"
                  className={
                    burger
                      ? "navbar-burger burger is-active"
                      : "navbar-burger burger"
                  }
                  onClick={() => setBurger(!burger)}
                >
                  <span aria-hidden="true"></span>
                  <span aria-hidden="true"></span>
                  <span aria-hidden="true"></span>
                </a>
              </div>

              <div className={burger ? "navbar-menu is-active" : "navbar-menu"}>
                <div className="navbar-end">
                  <div className="navbar-item">
                    {!isAuth ? (
                      <a
                        onClick={() => handleLogin()}
                        className=" button is-warning"
                      >
                        Submit a resource
                      </a>
                    ) : (
                      <Link className=" button is-warning" to="/submit">
                        Submit a resource
                      </Link>
                    )}
                  </div>
                  <Link to="/newsletter" className="navbar-item">
                    Newsletter
                  </Link>
                  {isAuth ? (
                    <div className="navbar-item has-dropdown is-hoverable">
                      <a className="navbar-link" id="copy-email">
                        Account
                      </a>
                      <div className="navbar-dropdown">
                        <p className="navbar-item">{user.username}</p>
                        <a className="navbar-item" onClick={e => logout(e)}>
                          Logout
                        </a>
                      </div>
                    </div>
                  ) : (
                    <a onClick={() => handleLogin()} className="navbar-item">
                      Login
                    </a>
                  )}
                </div>
              </div>
            </div>
            {!isAuth ? <SignupModal /> : ""}
          </nav>
        )}
      </AuthConsumer>
    </AuthProvider>
  )
}

export default Navbar
