import React from "react"

const Footer = () => {
  const date = new Date()
  const year = date.getFullYear()

  return (
    <footer className="footer has-background-dark">
      <div className="container">
        <div className="columns">
          <div className="column is-12">
            <div className="content">
              <p className="has-text-white">
                Created by the team behind{" "}
                <a
                  className="has-text-white"
                  href="https://growkarma.io"
                  target="_blank"
                >
                  <u>Growkarma | Design better learning</u>
                </a>
              </p>
              <p className="has-text-white has-opacity-65">
                © {year}, All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
