import React, { useState, useEffect } from "react"
// import { navigate } from "gatsby"

import axios from "axios"

const AuthContext = React.createContext()

const AuthProvider = props => {
  const [isAuth, setIsAuth] = useState(false)

  const [user, setUser] = useState()

  const [errorMessage, setErrorMessage] = useState(null)

  useEffect(() => {
    const localUser = localStorage.getItem("user")
    if (localUser) {
      setIsAuth(true)
      setUser(JSON.parse(localUser))
      // console.log(user);
    }
  }, [])

  const login = e => {
    e.preventDefault()
    if (document.querySelector(".login-email").value.length < 1) {
      setErrorMessage("Email field is empty")
      return
    } else if (document.querySelector(".login-password").value.length < 1) {
      setErrorMessage("Password field is empty")
      return
    } else {
      axios
        .post(process.env.API_URL + "/auth/local", {
          identifier: document.querySelector(".login-email").value,
          password: document.querySelector(".login-password").value,
        })
        .then(function (res) {
          console.log(res)
          if (res.status) {
            if (res.data.jwt) {
              console.log("User profile", res)
              console.log("User token", res.data.jwt)
              localStorage.setItem("token", res.data.jwt)
              localStorage.setItem("user", JSON.stringify(res.data.user))
              setUser(res.data.user)
              setIsAuth(true)
              setErrorMessage()
              window.location.reload()
            } else {
              setErrorMessage(res.data.message)
              console.log(res.data)
            }
          } else {
            console.log(res)
          }
        })
        .catch(function (error) {
          console.log(error)
          setErrorMessage("Incorrect credentials")
        })
    }
  }

  const logout = (e, history) => {
    e.preventDefault()
    localStorage.removeItem("user")
    localStorage.removeItem("token")
    setIsAuth(false)
    setUser(null)
    // navigate("/")
    window.location.reload()
  }

  return (
    <AuthContext.Provider
      value={{
        isAuth: isAuth,
        login: login,
        logout: logout,
        user: user,
        errorMessage: errorMessage,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}

const AuthConsumer = AuthContext.Consumer

export { AuthProvider, AuthConsumer }
