import React, { useState } from "react"
import { AuthProvider, AuthConsumer } from "./utils/AuthContext"
import axios from "axios"

const SignupModal = () => {
  const [signupTab, setSignupTab] = useState(false)
  const [error, setError] = useState(null)
  const [verification, setVerification] = useState(false)
  const [email, setEmail] = useState("")

  const handleClose = e => {
    e.preventDefault()
    document.querySelector(".signup-modal").classList.toggle("is-active")
  }

  const signup = e => {
    e.preventDefault()
    axios
      .post(process.env.API_URL + "/auth/local/register", {
        username: document.querySelector(".signup-name").value,
        email: document.querySelector(".signup-email").value,
        password: document.querySelector(".signup-password").value,
      })
      .then(response => {
        console.log("Acount created, please verify email")
        setError(null)
        setEmail(document.querySelector(".signup-email").value)
        setVerification(true)
      })
      .catch(error => {
        console.log(error.response)
        console.log(
          "An error occurred:",
          error.response.data.message[0].messages[0].message
        )
        setError(error.response.data.message[0].messages[0].message)
      })
  }
  return (
    <AuthProvider>
      <AuthConsumer>
        {({ login, errorMessage }) => (
          <div className="modal signup-modal">
            <div
              className="modal-background"
              onClick={e => handleClose(e)}
            ></div>
            <div className="modal-card">
              <header className="modal-card-head">
                <p className="modal-card-title is-flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 50 50"
                    fill="#181f40"
                    className="is-20 mr-2"
                  >
                    <path
                      d="M 34.800781 33.800781 C 33.101563 33.101563 31 32.199219 31 31.5 L 31 27 C 33.5 25.101563 35 22.101563 35 19 L 35 13 C 35 7.5 30.5 3 25 3 C 19.5 3 15 7.5 15 13 L 15 19 C 15 22.101563 16.5 25.199219 19 27 L 19 31.5 C 19 32.101563 16.898438 33 15.199219 33.800781 C 11.101563 35.5 5 38.101563 5 45 L 5 46 L 45 46 L 45 45 C 45 38.101563 38.898438 35.5 34.800781 33.800781 Z"
                      fill="#181f40"
                    />
                  </svg>
                  <span>Create an account</span>
                </p>
                <button
                  className="delete"
                  aria-label="close"
                  onClick={e => handleClose(e)}
                ></button>
              </header>

              {verification ? (
                <section className="modal-card-body">
                  <div className="content">
                    <p className="has-text-dark">
                      A verification email was sent to {email}
                    </p>
                    <p className="has-text-dark">
                      We are being respectful towards your privacy and won't
                      automatically subscribe you to any of our newsletters. If
                      you voluntarily want to receive our weekly email, you can
                      do it right here.
                    </p>
                    <form
                      method="post"
                      action="https://www.mailblast.io/lists/31053643-35c1-45cf-8677-acdf25affdad/subscribers/create_external"
                      className="mb-form"
                      noValidate="novalidate"
                    >
                      <div className="field has-addons is-marginless">
                        <div className="control" style={{ width: "100%" }}>
                          <input
                            type="email"
                            name="subscriber[email]"
                            className="input is-fullwidth"
                            required=""
                            defaultValue={email}
                            aria-required="true"
                            placeholder="Your email address"
                          />
                        </div>
                        <div className="control">
                          <button type="submit" className="button is-primary">
                            Subscribe
                          </button>
                        </div>
                      </div>

                      <div
                        className="mb-form-group-tk is-hidden"
                        aria-hidden="true"
                      >
                        <input
                          type="text"
                          name="mb_3105364335c145cf8677acdf25affdad"
                          tabIndex="-1"
                          value=""
                        />
                      </div>
                    </form>
                  </div>
                </section>
              ) : (
                <form action="">
                  <section className="modal-card-body">
                    <div className="content">
                      <p className="has-text-white has-background-dark py-3 px-3">
                        For the safety and accuracy of our list, you need to
                        login to submit or upvote a resource.
                      </p>
                      <div className="tabs">
                        <ul className="is-marginless">
                          <li className={!signupTab ? "is-active" : ""}>
                            <a onClick={() => setSignupTab(false)}>Login</a>
                          </li>
                          <li className={signupTab ? "is-active" : ""}>
                            <a onClick={() => setSignupTab(true)}>
                              Create account
                            </a>
                          </li>
                        </ul>
                      </div>
                      {signupTab ? (
                        <div>
                          {/* Signup Form */}
                          <div className="field">
                            <label className="label is-size-7">Name</label>
                            <div className="control">
                              <input
                                className="input signup-name"
                                type="text"
                                placeholder="Michael Scott"
                              />
                            </div>
                          </div>

                          <div className="field">
                            <label className="label is-size-7">Email</label>
                            <div className="control">
                              <input
                                className="input signup-email"
                                type="email"
                                placeholder="michaelscott@mail.com"
                              />
                            </div>
                          </div>

                          <div className="field">
                            <label className="label is-size-7">Password</label>
                            <div className="control">
                              <input
                                className="input signup-password"
                                type="password"
                                placeholder=""
                              />
                            </div>
                          </div>
                          {error ? (
                            <span className="tag is-medium is-danger mt-4">
                              {error}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        <div>
                          {/* Login Form */}
                          <div className="field">
                            <label className="label is-size-7">Email</label>
                            <div className="control">
                              <input
                                className="input login-email"
                                type="email"
                                placeholder="michaelscott@mail.com"
                              />
                            </div>
                          </div>

                          <div className="field">
                            <label className="label is-size-7">Password</label>
                            <div className="control">
                              <input
                                className="input login-password"
                                type="password"
                                placeholder=""
                              />
                            </div>
                          </div>
                          {errorMessage ? (
                            <span className="tag is-danger is-medium">
                              {errorMessage}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    </div>
                  </section>

                  <footer className="modal-card-foot">
                    {signupTab ? (
                      <button
                        className="button is-primary"
                        onClick={e => signup(e)}
                      >
                        Create account
                      </button>
                    ) : (
                      <button
                        className="button is-primary"
                        onClick={e => {
                          login(e)
                          // handleClose(e)
                        }}
                      >
                        Login
                      </button>
                    )}
                    <button className="button" onClick={e => handleClose(e)}>
                      Cancel
                    </button>
                  </footer>
                </form>
              )}
            </div>
          </div>
        )}
      </AuthConsumer>
    </AuthProvider>
  )
}

export default SignupModal
